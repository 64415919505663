<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="localIsReady"
          @change="$emit('update:isReady', $event)"
          :false-value="0"
          :true-value="1"
          label="Дом сдан"
        ></v-checkbox>
      </v-col>
      <!--<v-col cols="2">
        <v-select
          :value="buildOverlap"
          @input="$emit('update:buildOverlap', $event)"
          :items="buildOverlapItems"
          item-value="id"
          item-text="value"
          label="Перекрытия"
        ></v-select>
      </v-col>-->
      <v-col cols="2">
        <v-select
          :value="districtOfficial"
          @input="$emit('update:districtOfficial', $event)"
          :items="districtOfficialItemsWithEmpty"
          item-value="id"
          item-text="value"
          label="Район общегородской"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="buildYear"
          @input="$emit('update:buildYear', Number($event))"
          label="Год постр."
          type="number"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="totalFloors"
          @input="$emit('update:totalFloors', $event)"
          label="Этажность"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          :value="apartmentComplex"
          @input="$emit('update:apartmentComplex', $event)"
          :items="apartmentComplexItemsWithEmpty"
          item-value="id"
          item-text="value"
          label="ЖК"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-select
          :value="districtCustom"
          @input="$emit('update:districtCustom', $event)"
          :items="districtCustomItemsWithEmpty"
          item-value="id"
          item-text="value"
          label="Район на сайте"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="frontDoorsCount"
          @input="$emit('update:frontDoorsCount', $event)"
          label="кол-во подъездов"
        ></v-text-field>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="totalApartments"
          @input="$emit('update:totalApartments', $event)"
          label="кол-во квартир"
        ></v-text-field>
      </v-col>
      <v-col cols="4">
        <v-autocomplete
          :menu-props="{ maxHeight: '650' }"
          :value="builder"
          @input="$emit('update:builder', $event)"
          :items="builderItemsWithEmpty"
          item-value="id"
          item-text="value"
          label="Застройщик"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-select
          :value="subdistrict"
          @input="$emit('update:subdistrict', $event)"
          :items="subdistrictItemsRelated"
          item-value="id"
          item-text="value"
          label="Подрайон"
          :disabled="!districtCustom"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          :value="buildingMaterial"
          @input="$emit('update:buildingMaterial', $event)"
          :items="buildingMaterialItems"
          item-value="id"
          item-text="value"
          label="Мат. стен"
        />
      </v-col>
      <v-col cols="2">
        <v-select
          :value="buildOverlap"
          @input="$emit('update:buildOverlap', $event)"
          :items="buildOverlapItems"
          item-value="id"
          item-text="value"
          label="Перекрытия"
        ></v-select>
      </v-col>
      <v-col cols="2">
        <v-text-field
          :value="buildingSerial"
          @input="$emit('update:buildingSerial', $event)"
          label="Серия дома"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <v-select
          :value="buildingType"
          @input="$emit('update:buildingType', $event)"
          :items="buildingTypeItems"
          item-value="id"
          item-text="value"
          label="Тип жилья"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-select
          :menu-props="{ maxHeight: '650' }"
          :value="managerCompany"
          @input="$emit('update:managerCompany', $event)"
          :items="managerCompanyItems"
          item-value="id"
          item-text="value"
          label="Управляющая компания"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <v-textarea
          :value="descriptionEntrance"
          @input="$emit('update:descriptionEntrance', $event)"
          outlined
          label="Источники"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <v-textarea
          :value="descriptionService"
          @input="$emit('update:descriptionService', $event)"
          outlined
          label="Служебное описание "
        ></v-textarea>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
        class="d-flex justify-lg-start"
      >
        <v-text-field
          :value="overhaul"
          @input="$emit('update:overhaul', $event)"
          label="капремонт"
          class="pr-6"
        ></v-text-field>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [serviceDataComputeds],
  name: 'BuildingInfo',
  props: [
    'builder',
    'districtCustom',
    'districtOfficial',
    'subdistrict',
    'buildingMaterial',
    'buildYear',
    'buildingType',
    'totalFloors',
    'totalApartments',
    'buildingSerial',
    'frontDoorsCount',
    'overhaul',
    'apartmentComplex',
    'managerCompany',
    'descriptionService',
    'descriptionEntrance',
    'isReady',
    'buildOverlap'
  ],
  data () {
    return {
      localIsReady: this.isReady
    }
  },
  computed: {
    subdistrictItemsRelated () {
      return this.subdistrictItems ? this.subdistrictItems.filter(item => item.parent_id === this.districtCustom || item.id === null) : null
    }
  },
  watch: {
    districtCustom (oldVal, newVal) {
      if (oldVal !== newVal) {
        this.$emit('update:subdistrict', null)
      }
    }
  }
}
</script>

<style scoped>

</style>
